import React, { useState, useEffect } from 'react';

export const PromoSticker = ({ lang }) => {
  const defaultLang = 'ro';
  const currentLang = (lang && ['ro', 'ru'].includes(lang)) ? lang : defaultLang;

  const text = {
    ro: {
      discount: "-15%",
      description: "pentru orice asigurare"
    },
    ru: {
      discount: "-15%",
      description: "на любую страховку"
    }
  };

  return (
    <div className="fixed z-[9999] 
                    bottom-4 right-4
                    sm:bottom-6 sm:right-6 
                    md:bottom-8 md:right-8 
                    lg:bottom-10 lg:right-10 
                    transform hover:scale-105 transition-all duration-300">
      <div className="relative">
        <div className="absolute inset-0 bg-red-500 rounded-full blur-xl opacity-20 animate-pulse"></div>

        <div className="relative bg-gradient-to-br from-red-600 to-red-700 
                      text-white 
                      p-3 sm:p-4 
                      rounded-full 
                      w-20 h-20 
                      sm:w-24 sm:h-24 
                      md:w-28 md:h-28 
                      lg:w-32 lg:h-32 
                      flex flex-col items-center justify-center
                      shadow-[0_0_20px_rgba(220,38,38,0.3)]
                      transform rotate-12 hover:rotate-6 
                      transition-all duration-300 cursor-pointer
                      border-2 border-red-400">

          <span className="text-xl sm:text-2xl md:text-3xl font-bold 
                         bg-clip-text text-transparent 
                         bg-gradient-to-r from-white to-red-100">
            {text[currentLang].discount}
          </span>

          <span className="text-[10px] sm:text-xs md:text-sm text-center mt-1 
                         font-medium text-red-100 leading-tight">
            {text[currentLang].description}
          </span>
        </div>
      </div>
    </div>
  );
};

export const PromoBanner = ({ lang }) => {
  const defaultLang = 'ro';
  const currentLang = (lang && ['ro', 'ru'].includes(lang)) ? lang : defaultLang;
  const [daysLeft, setDaysLeft] = useState(null);

  const text = {
    ro: {
      offer: "Profită de oferta noastră!",
      discount: "-15% reducere",
      forAny: "pentru orice tip de asigurare până la",
      daysLeft: "zile rămase"
    },
    ru: {
      offer: "Воспользуйтесь нашим предложением!",
      discount: "-15% скидка",
      forAny: "на любой вид страхования до",
      daysLeft: "дней осталось"
    }
  };

  useEffect(() => {
    try {
      const endDate = new Date('2024-12-31');
      const today = new Date();
      const diffTime = Math.max(0, endDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysLeft(diffDays);
    } catch (error) {
      console.error('Error calculating days:', error);
      setDaysLeft(0);
    }
  }, []);

  if (daysLeft === null) return null;

  return (
    <div className="fixed top-[4.5rem] sm:top-20 left-0 right-0 z-[60]">
      <div className="max-w-7xl mx-auto px-2 sm:px-4">
        <div className="bg-gradient-to-r from-red-600 to-red-500 
                    text-white text-center
                    py-1 px-2
                    sm:py-2 sm:px-4
                    text-xs sm:text-sm md:text-base
                    shadow-lg
                    flex flex-wrap justify-center items-center gap-1 sm:gap-2
                    rounded-lg sm:rounded-xl md:rounded-2xl">
          <span>{text[currentLang].offer}</span>
          <span className="font-bold bg-red-700 px-2 py-0.5 rounded-full whitespace-nowrap">
            {text[currentLang].discount}
          </span>
          <span className="hidden xs:inline">{text[currentLang].forAny}</span>
          <span className="font-semibold whitespace-nowrap">31.12.2024</span>
          <div className="bg-white/20 px-2 py-0.5 rounded-full 
                       text-xs sm:text-sm font-medium
                       whitespace-nowrap ml-1">
            <span className="font-bold">{daysLeft}</span> {text[currentLang].daysLeft}
          </div>
        </div>
      </div>
    </div>
  );
};